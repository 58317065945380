<template>
  <div></div>
</template>

<script>
import {
  authorize,
  activityDetail,
  activityApply,
  isFocuson,
} from "../../api/home/index.js";
export default {
  created() {
    if (!this.$route.query.t) {
      this.authorize();
    } else {
      console.log(window.location.href, this.$route.query.a, "index.vue");
      window.sessionStorage.setItem("token", this.$route.query.t);
      // window.sessionStorage.setItem("activityId", this.$route.query.a);
      window.location.href = `${window.location.origin}/home?a=${this.$route.query.a}`;
      // this.$router.push({
      //   path: "/home",
      //   query: {
      //     a: this.$route.query.a,
      //   },
      // });
    }
  },
  methods: {
    // 授权
    async authorize() {
      window.location.href = `${window.location.origin}/api/wechat/authorize?a=${this.$route.query.a}`;
    },
  },
};
</script>

<style>
</style>